























































import {Component, Ref, Vue} from "vue-property-decorator";
import CustomFormDataTableDetail from "@/components/CustomForm/TableDetail/index.vue";
import {
  AttachmentHostType,
  AuditFlowScope,
  CustomFormType, ServiceObjectFeedbackDto, TrainApplyDto,
} from "@/api/appService";
import api from "@/api/index";
import ExportWord from "@/components/DownloadWord/index.vue";
import AuditBlock, {AuditApi} from "@/components/Audit/AuditBlock.vue";


@Component({
  name: "CustomFormStandardDetail",
  components: {
    CustomFormDataTableDetail,
    ExportWord,
    AuditBlock,
  },
})
export default class CustomFormStandardDetail extends Vue {


  private dataId!: string;
  private formId!: string;

  private isProject = false;

  isReady = false;

  selector = "detail-wrap";
  filename = "";
  defaultName = "合作方申请表-";
  styles = "";
  detail: ServiceObjectFeedbackDto = {
    service: {},
    creatorUser: {},
    serviceObject: {},
  };

  get hostType() {
    return CustomFormType.ServiceObjectFeedback;
  }

  created() {
    this.dataId = this.$route.query.id as string;
    this.formId = this.$route.query.formId as string;

    this.isProject = true;

    if ((this.dataId && this.hostType) || this.formId) {
      this.isReady = true;
    } else {
      this.$message.error("缺少url参数 id 或 hostType，请检查!");
    }
  }

  getData() {
    return api.serviceObjectFeedback
      .get({id: parseInt(this.dataId)})
      .then((res) => {
        this.detail = {...res}
        return res
      });
  }

  // 返回
  private cancel() {
    this.$router.back();
  }
}
